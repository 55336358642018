import classNames from 'classnames';
import { Transition } from '@headlessui/react';
import { EnvelopeIcon } from '@heroicons/react/24/outline';
import { useUser, useSupabaseClient } from '@supabase/auth-helpers-react';
import { trackEvent } from '../utils/analytics';
const EmailForm = ({
  firstSignup,
  signInMessage,
  emailUploading,
  submitEmailForSignIn,
  translator
}) => {
  const supabaseClient = useSupabaseClient();

  const signInWithGoogle = async () => {
    trackEvent('email_sign_in', {
      event_category: 'conversion',
      event_label: 'email_submission_google'
    });

    const { error } = await supabaseClient.auth.signInWithOAuth({
      provider: 'google'
    });
    console.log(error);
  };

  return (
    <div className="flex flex-col items-start">
      <form
        action="#"
        className="sm:mx-auto sm:mt-4 lg:mx-0"
        onSubmit={submitEmailForSignIn}
      >
        <div className="sm:flex items-center mx-auto justify-center">
          <div className="min-w-[320px] md:min-w-[60%]  flex   relative mt-4 sm:mt-0 py-2 h-20 ">
            <label htmlFor="email" className="sr-only">
              {translator('email_address')}
            </label>
            <EnvelopeIcon className="pointer-events-none w-6 h-6 absolute top-1/2 transform -translate-y-1/2 left-3 text-slate-600" />
            <input
              id="email"
              type="email"
              placeholder={translator('Enter your email')}
              className={classNames(
                firstSignup && !signInMessage
                  ? 'border-red-400 border-2'
                  : 'border-indigo-400 border-0',
                signInMessage && signInMessage !== 'unsupported_otp_type'
                  ? 'border-green-200 border-2'
                  : '',
                signInMessage === 'unsupported_otp_type'
                  ? 'border-red-400 border-2'
                  : '',
                'block w-full shadow-md rounded-lg px-11 py-2.5 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-900'
              )}
            />
          </div>
          <div className="mt-3.5 sm:mt-0 sm:ml-3 min-w-[40%] ">
            <button
              type="submit"
              className={`block  w-full rounded-full  bg-slate-800 py-5 px-5 font-extrabold text-white shadow hover:bg-slate-500 focus:outline-none focus:ring-2 focus:ring-cyan-400 focus:ring-offset-2 focus:ring-offset-gray-900 ${
                emailUploading ? 'animate-pulse' : ''
              }`}
            >
              {emailUploading ? (
                <span>{translator('sending_email')}</span>
              ) : (
                <span className="flex-nowrap whitespace-nowrap">
                  {translator('main_cta')}
                </span>
              )}
            </button>
          </div>
        </div>
        {firstSignup && !signInMessage && (
          <p className="mt-1 text-sm text-red-500 font-semibold">
            {translator('email_confirm')}
          </p>
        )}
        {signInMessage === 'unsupported_otp_type' && (
          <p className="mt-1 text-sm text-red-400 font-semibold">
            {translator('email_invalid')}
          </p>
        )}
        <Transition
          show={!!(signInMessage && signInMessage !== 'unsupported_otp_type')}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-0"
          enterTo="opacity-100 scale-100"
          leave="duration-0 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
          className={'w-full my-2 space-y-2'}
        >
          <div className="mt-2 bg-green-50 border-2 border-green-500 rounded-lg flex flex-row items-center justify-center text-green-700 py-3 ">
            <EnvelopeIcon className="w-6"></EnvelopeIcon>
            <p className="ml-2 text-sm font-semibold">{signInMessage}</p>
          </div>
        </Transition>
      </form>
      <div className="flex self-center hp-center-text mt-3 items-center justify-center text-center">
        <span>or</span>
      </div>
      <div className="flex  justify-center relative w-full mt-3 p-2">
        <div className=" flex justify-center w-full md:w-auto  bg-gradient-to-r from-[#3864FF] to-[#F1148B]   p-0.5 rounded-full  ">
          <button
            className="bg-white py-2 shadow-sm  flex items-center w-full justify-center rounded-full  p-4"
            onClick={() => signInWithGoogle()}
          >
            <img
              src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/8399/G-on-clear.svg"
              alt="Google logo"
            />
            <span className="text-[#4138C2] text-sm font-bold">
              {translator('sign_up_google')}
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmailForm;
