import { Fragment, useState } from 'react';
import { Dialog, Transition, Switch } from '@headlessui/react';

export default function ProjectModal({
  openModal,
  passOpenModal,
  userId,
  inspirationImages,
  setMyStyles,
  myStyles
}) {
  const [open, setOpen] = useState(true);
  const [enabled, setEnabled] = useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const project_data = {
      name: e.target.project_name.value,
      public_name: e.target.project_name.value,
      is_public: false,
      can_be_public: enabled,
      inspiration_images: inspirationImages,
      tags: [],
      user_id: userId
    };

    const project = await fetch('/api/community', {
      method: 'POST',
      body: JSON.stringify(project_data)
    });

    const { data, error } = await project.json();

    if (error) throw Error(error.message);

    e.target.project_name.value = '';

    setMyStyles([...myStyles, data]);

    // setSelectedProject({
    //   name: data.project_name,
    //   display_name: data.project_name,
    //   ...data
    // });

    passOpenModal(false);
  };

  return (
    <Transition.Root show={!!openModal} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={passOpenModal}>
        <Transition.Child
          // as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed pt-0 inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              // as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden mx-2 my-5 rounded-lg bg-white px-8 pt-6 pb-6 text-left shadow-xl transition-all sm:my-8 sm:mx-8 sm:w-full max-w-lg sm:p-6">
                <span
                  onClick={() => {
                    passOpenModal(false);
                  }}
                  className="absolute right-3 top-2 font-bold text-indigo-400 cursor-pointer"
                >
                  X
                </span>
                <div className="mx-auto max-w-sm">
                  <div className="">
                    <p className="font-bold text-slate-700">
                      Save this style as
                    </p>
                    <form
                      className="mt-2 flex sm:items-center flex-col gap-4"
                      onSubmit={(e) => {
                        handleSubmit(e);
                      }}
                    >
                      <div className="w-full sm:max-w-xs">
                        <label htmlFor="email">Custom Style Name</label>
                        <input
                          type="text"
                          name="project_name"
                          id="project_name"
                          className="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="My Custom Boho Modern Mix"
                        />
                      </div>
                      <div className="w-full sm:max-w-xs">
                        <label htmlFor="email">
                          Allow the community to use this style?{' '}
                        </label>
                        <div className="flex flex-row gap-4 mt-2">
                          <span>{enabled ? 'Yes' : 'No'}</span>
                          <Switch
                            checked={enabled}
                            onChange={setEnabled}
                            className={`${
                              enabled ? 'bg-blue-600' : 'bg-gray-200'
                            } relative inline-flex h-6 w-11 items-center rounded-full`}
                          >
                            <span className="sr-only">
                              {' '}
                              Allow the community to use this style?
                            </span>
                            <span
                              className={`${
                                enabled ? 'translate-x-6' : 'translate-x-1'
                              } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                            />
                          </Switch>
                        </div>
                      </div>
                      <button
                        type="submit"
                        name="submit"
                        className="mt-3 inline-flex w-full items-center justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:mt-0 sm:ml-3 "
                      >
                        Save
                      </button>
                    </form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
