import { Disclosure } from '@headlessui/react';
import { MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/24/outline';

const faqs = [
  {
    key: 'wergsdg1234',
    question: 'What is SofaBrain?',
    answer: `SofaBrain is an innovative interior design app, leveraging the power of Artificial Intelligence to instantly transform the look of any room. By simply uploading an image of your space, SofaBrain can redesign it in various styles, providing quick and exciting alternatives. It's an invaluable tool for interior designers, architects, real estate agents, or DIY enthusiasts, helping to streamline the design process and save hours of time.`
  },
  {
    key: 'bgrhert1234',
    question:
      "Can I use SofaBrain even if I'm not a professional interior designer?",
    answer:
      'Absolutely! SofaBrain is designed for everyone - from professional interior designers to DIY enthusiasts. Our user-friendly interface makes it easy to transform your room, no design experience required!'
  },
  {
    key: 'cgewrgg1234',
    question: 'Is there a cost to use SofaBrain?',
    answer: `SofaBrain provides 3 free renders for you to try our service. Beyond these initial designs, we offer a variety of premium options to continue enjoying our vast selection of styles and other exclusive features.`
  },
  {
    key: 'fdsgd1234',
    question: 'Can I use SofaBrain for Virtual Staging?',
    answer:
      '<p>SofaBrain uses an extremely advanced AI that has been optimized for both regular rooms and empty rooms. It can both redecorate and fill a space with the adequate furniture and it is the perfect virtual staging solution.</p>'
  },
  {
    key: 'easd1234',
    question: 'What types of design styles does SofaBrain offer?',
    answer:
      "SofaBrain offers a wide array of design styles, from modern and minimalist to traditional and bohemian. Whether you're looking for a complete style overhaul or just some fresh inspiration, we've got you covered!"
  }
  // More questions...
];

export default function FaqHome() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-2xl lg:px-0">
        <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
          {/* <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h2> */}
          <dl className="mt-0 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <div>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-medium leading-7 text-[#4138C2]">
                          {faq.question}
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusCircleIcon
                              className="h-6 w-6 text-[#4138C2]"
                              aria-hidden="true"
                            />
                          ) : (
                            <PlusCircleIcon
                              className="h-6 w-6 text-[#4138C2]"
                              aria-hidden="true"
                            />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <div
                        className="text-base leading-7 text-gray-600 pl-2"
                        dangerouslySetInnerHTML={{ __html: faq.answer }}
                      ></div>
                      {/* <p className="text-base leading-7 text-gray-600">{faq.answer}</p> */}
                    </Disclosure.Panel>
                  </div>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
