import EmailForm from './EmailForm';

const NotLoggedIn = ({
  firstSignup,
  signInMessage,
  emailUploading,
  submitEmailForSignIn,
  selectFile,
  imageUrl,
  checkIfLoggedIn,
  translator
}) => {
  return (
    <div className=" mt-3 sm:mt-2 grid grid-col items-center md:items-center justify-center">
      <EmailForm
        firstSignup={firstSignup}
        signInMessage={signInMessage}
        emailUploading={emailUploading}
        submitEmailForSignIn={submitEmailForSignIn}
        translator={translator}
      />

      {!firstSignup && !signInMessage && (
        <div className="text-center text-sm mt-4">
          <span className="text-[#9192A3]">{translator('haveaccount')}</span>{' '}
          <a href="/signin" className="text-[#4138C2] font-bold">
            Login
          </a>
        </div>
      )}
    </div>
  );
};

export default NotLoggedIn;
