import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
  SunIcon,
  BoltIcon,
  EyeIcon,
  Square3Stack3DIcon,
  ArrowPathRoundedSquareIcon
} from '@heroicons/react/24/outline';

export default function HelperProModal({ openModal, passOpenModal }) {
  const [open, setOpen] = useState(true);
  const features = [
    {
      name: 'Harness the power of unlimited renders',
      description:
        "Did you know that each render is different from the last, even with the same image and style? Keep hitting that render button until you get the perfect look and feel you're after",
      icon: Square3Stack3DIcon
    },
    {
      name: 'Remix for even better renders',
      description:
        'Love a previous render? You can click the remix arrow on that render and use it as your input image. Re-rendering your favorite renders can yield incredible results',
      icon: ArrowPathRoundedSquareIcon
    },
    {
      name: 'Multiple perspectives',
      description:
        'Take multiple photos of the same room. Use your unlimited renders to try styles from all angles and discover your perfect space',
      icon: EyeIcon
    },
    {
      name: 'Bright rooms do better',
      description:
        'Be sure your image is bright and clear for the best possible render',
      icon: SunIcon
    },
    {
      name: 'Use "Rennovation" mode for oomph',
      description:
        'If you are using an empty room or want to really increase the creative strength, choose Rennovation instead of Interior Design. Expect the AI to pay less attention to current furniture, walls and colors',
      icon: BoltIcon
    }
  ];

  return (
    <Transition.Root show={!!openModal} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={passOpenModal}>
        {/* <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        > */}
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        {/* </Transition.Child> */}

        <div className="fixed pt-16 inset-0 z-100 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              // as={Fragment}
              enter="duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden mx-2 my-5 rounded-lg bg-white px-3 pt-4 pb-4 text-left shadow-xl transition-all sm:my-8 sm:mx-8 sm:w-full sm:max-w-4xl sm:p-6">
                <div className="text-right">
                  <span
                    onClick={() => {
                      passOpenModal(false);
                    }}
                    className="p-4 font-bold text-indigo-400 cursor-pointer"
                  >
                    X
                  </span>
                </div>
                <div className="mx-auto mt-2 max-w-2xl lg:max-w-4xl">
                  <dl className="grid max-w-xl grid-cols-1 gap-y-6 gap-x-8 lg:max-w-none lg:grid-cols-1 lg:gap-y-8">
                    {features.map((feature) => (
                      <div key={feature.name} className="relative pl-16">
                        <dt className="text-base font-semibold leading-7 text-gray-900">
                          <div className="absolute top-1 left-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                            <feature.icon
                              className="h-6 w-6 text-white"
                              aria-hidden="true"
                            />
                          </div>
                          {feature.name}
                        </dt>
                        <dd className="mt-0 text-base leading-7 text-gray-600">
                          {feature.description}
                        </dd>
                      </div>
                    ))}
                  </dl>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
