import { useState } from 'react';
import Image from 'next/image';
import { transferableAbortController } from 'util';
import { CheckCircleIcon } from '@heroicons/react/24/outline';

const StyleSelector = ({ translator }) => {
  const styles = [
    {
      name: 'Bohemian',
      image: '/isobohemian.png',
      actual: '/bohemianbedroom.jpeg'
    },
    {
      name: 'Contemporary',
      image: '/isocontemporary.png',
      actual: '/comtemporarybedroom.jpeg'
    },
    {
      name: 'Neoclassical',
      image: '/isoneo.png',
      actual: '/midcentury.jpeg'
    },
    {
      name: 'Eclectic',
      image: '/isoeclectic.png',
      actual: '/eclecticbedroom.jpeg'
    }

    // {
    //   name: 'Bohemian',
    //   image: '/isobohemian.png'
    // }
  ];

  const [selectedStyle, setSelectedStyle] = useState(styles[0]);

  return (
    <div className="flex items-center flex-col gap-4 p-4">
      <div className="flex-col justify-start items-center md:items-start gap-2 flex lg:hidden lg:">
        <div className="justify-end md:justify-end items-end gap-2 flex flex-row hanno-font sm:text-[38px] text-xl mt-1">
          <span className="capitalize">{translator('try1')}</span>
          <span className="gradient-text">sofabrain</span>
          <span className="text-zinc-800 capitalize  ">
            {translator('try2')}
          </span>
        </div>
        <div className=" text-zinc-500 text-base font-normal self-center ">
          {translator('try3')}
        </div>
      </div>

      <div className="flex flex-col md:flex-row md:gap-10">
        <div className="m-10 md:m-0 md:flex  ">
          <Image
            className="rounded-[20px] shadow object-cover aspect-square"
            src={selectedStyle.actual} // Dynamically update based on selected style
            alt={selectedStyle.name}
            height={691}
            width={691}
          />
        </div>

        <div className="flex-col justify-center md:justify-start r md:items-start gap-10 inline-flex">
          <div className="flex-col justify-center md:justify-start items-center md:items-start gap-2  hidden lg:flex ">
            <div className="justify-center md:justify-start items-end gap-2 flex flex-row hanno-font md:text-[24px] lg:text-[32px] text-2xl mt-1">
              <span className="capitalize">{translator('try1')}</span>
              <span className="gradient-text">sofabrain</span>
              <span className="text-zinc-800 capitalize whitespace-nowrap">
                {translator('try2')}
              </span>
            </div>
            <div className=" text-zinc-500 text-base font-normal">
              {translator('try3')}
            </div>
          </div>
          <div className="flex-col justify-center lg:justify-start items-center lg:items-start gap-4 flex">
            <div className=" text-zinc-800 text-lg font-extrabold capitalize">
              {translator('try4')}
            </div>
            <div className="lg:justify-start justify-center items-center lg:items-start gap-6 grid grid-cols-2 px-4">
              {styles.map((style) => (
                <div
                  key={style.name}
                  className="pb-6 bg-white rounded-[20px] shadow border border-neutral-100 flex-col justify-start items-center gap-4 inline-flex cursor-pointer relative "
                  onClick={() => setSelectedStyle(style)}
                >
                  <Image
                    className={`rounded-tl-[20px] rounded-tr-[20px] object-cover ${
                      style.name == selectedStyle.name ? '' : 'filter grayscale'
                    }`}
                    height={149}
                    width={215}
                    src={style.image}
                    alt={style.name}
                  />
                  <CheckCircleIcon
                    className={`h-8 w-8 text-pink-500 top-2 right-2 ${
                      style.name == selectedStyle.name ? 'absolute' : 'hidden'
                    }`}
                  />
                  <div className="self-stretch h-[19px] px-4 flex-col justify-start items-center gap-2 flex">
                    <div className="text-zinc-800 text-base font-normal">
                      {style.name}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StyleSelector;
