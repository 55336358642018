import React, { useState, useEffect, useRef } from 'react';
import RenderItem from 'components/RenderItem';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function PreparingRenders({
  setOpenModal,
  subscription,
  currentRenders,
  handleEditMode,
  editableRender,
  hijackAdvancedEditMode,
  setActiveInput
}) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 0); // 2 seconds

    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      className={classNames(
        loading ? 'hidden' : '',
        'md:grid md:justify-center grid grid-cols-1 gap-x-2 gap-2 transition-opacity'
      )}
    >
      {currentRenders.map((render, i) => {
        return (
          <RenderItem
            passOpenModal={setOpenModal}
            subscription={subscription}
            render={render}
            key={render?.id + '-' + i}
            preview={true}
            editable={true}
            addLoadingPercentage={false}
            HDImage={render?.quality}
            handleEditMode={handleEditMode}
            editableRender={editableRender}
            hijackAdvancedEditMode={hijackAdvancedEditMode}
            setActiveInput={setActiveInput}
          />
        );
      })}
    </div>
  );
}

//   <img className='w-full rounded-lg mt-4' src='japandi3.jpg'></img>
//           <div className='mt-3'>
//             <span className='bg-[#E6E5F3] text-[#4139C2] text-xs font-bold rounded-md p-1 pl-2 pr-2'>Japandi</span>
//           </div>
//           <div className='text-[#979AA1] text-xs mt-2'>
//             <span>Japandi design whispers harmony, where the elegant simplicity of Japan dances with the rustic warmth of Scandinavia, creating a serene sanctuary.</span>
//           </div>

export default PreparingRenders;
