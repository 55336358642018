import { useState } from 'react';

export default function FeedbackButton({ userId, url, feedback_type }) {
  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   const feedback_data = {
  //     feedback_type: feedback_type,
  //     feedback_text: e.target.feedback_text.value,
  //     user_id: userId
  //   };

  //   const feedback = await fetch('/api/feedback', {
  //     method: 'POST',
  //     body: JSON.stringify(feedback_data)
  //   });

  //   const { data, error } = await feedback.json();

  //   if (error) throw Error(error.message);

  //   e.target.feedback_text.value = '';
  // };

  // const handleFocus = (e) => {
  //   // take focus away from parent elements
  //   e.stopPropagation();
  // };

  return (
    <a href="/contact">
      <button className="md:block fixed bottom-4 right-4 border-3 text-sm border-indigo-200 drop-shadow-lg rounded-lg border bg-white z-50 p-2 pr-3 pl-3 ">
        Contact Us
      </button>
    </a>

    // <div className=" md:block fixed bottom-4 right-4 drop-shadow-lg rounded-lg border  bg-white  z-20">
    //   <form
    //     className="bg-white border-2 border-indigo-200 rounded-lg flex flex-col"
    //     acceptCharset="UTF-8"
    //     onSubmit={(e) => {
    //       handleSubmit(e);
    //     }}
    //   >
    //     <textarea
    //       placeholder="Contact Us"
    //       rows="3"
    //       minLength="3"
    //       className="bg-transparent cursor-pointer focus:cursor-text placeholder-shown:text-center focus:placeholder-shown:text-left placeholder:text-gray-800 dark:placeholder:text-gray-500 focus:placeholder:text-gray-400 dark:focus:placeholder:text-gray-500 border-0 outline-0 p-2 focus:ring-0 resize-none text-sm h-12 w-64 placeholder-shown:h-9 placeholder-shown:w-24 duration-100 transition-all focus:h-12 focus:w-64 p-2 peer"
    //       name="feedback_text"
    //       id="feedback_text"
    //       spellCheck="false"
    //     ></textarea>
    //     <div className="flex justify-between opacity-100 overflow-hidden p-2 peer-placeholder-shown:p-0 peer-placeholder-shown:opacity-100 font-medium text-sm duration-100 transition-all peer-placeholder-shown:w-0 peer-placeholder-shown:h-0 peer-focus:flex peer-focus:h-auto peer-focus:w-auto peer-focus:opacity-100 peer-focus:p-2">
    //       <p
    //         className="py-1 px-2 bg-gray-100  rounded-md"
    //         onClick={handleFocus}
    //       >
    //         Cancel
    //       </p>
    //       <input
    //         type="submit"
    //         name="submit"
    //         value="Send feedback"
    //         className="py-1 px-2 cursor-pointer bg-indigo-500 hover:bg-indigo-400 text-white rounded-md"
    //       />
    //     </div>
    //   </form>
    // </div>
  );
}
