import React from 'react';

export function GradientPhoto({ xkey }) {
  return (
    <div key={xkey} id="xx">
      <svg
        key={xkey}
        width="52"
        height="52"
        viewBox="0 0 52 52"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.43359 34.1743L16.416 23.1919C18.2865 21.3214 21.3192 21.3214 23.1897 23.1919L34.172 34.1743M30.9789 30.9811L33.9783 27.9817C35.8488 26.1112 38.8815 26.1112 40.752 27.9817L46.9447 34.1743M8.62675 42.1572H43.7515C45.515 42.1572 46.9447 40.7276 46.9447 38.964V13.4187C46.9447 11.6552 45.515 10.2256 43.7515 10.2256H8.62675C6.86322 10.2256 5.43359 11.6552 5.43359 13.4187V38.964C5.43359 40.7276 6.86322 42.1572 8.62675 42.1572ZM30.9789 18.2085H30.9948V18.2245H30.9789V18.2085ZM31.7772 18.2085C31.7772 18.6494 31.4198 19.0068 30.9789 19.0068C30.538 19.0068 30.1806 18.6494 30.1806 18.2085C30.1806 17.7676 30.538 17.4102 30.9789 17.4102C31.4198 17.4102 31.7772 17.7676 31.7772 18.2085Z"
          stroke={`url(#paint0_linear_2916_4117_${xkey})`}
          strokeWidth="3.19316"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <defs>
          <linearGradient
            id={`paint0_linear_2916_4117_${xkey}`}
            x1="5.66793"
            y1="28.8524"
            x2="49.0035"
            y2="28.8524"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#3864FF" />
            <stop offset="1" stopColor="#F1148B" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
