import dynamic from 'next/dynamic';
import { getData, evaluateFileType, getURL } from 'utils/helpers';
import { Transition } from '@headlessui/react';
import { useState, useEffect, useRef } from 'react';
import { useUser } from 'utils/useUser';
import { useTranslation, Trans } from 'next-i18next';
// import Image from 'next/image' as ImageNext;

// import aas nextImage

import NextImage from 'next/image';

import { useRouter } from 'next/router';
// import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
// import Feed from 'components/Feed';
export async function getStaticProps({ locale }) {
  console.log('loading translation for', locale);

  return {
    props: {
      ...(await serverSideTranslations(locale, ['common', 'menus'])) // 'menus' is used for component translation and common for page translation itself.
    }
  };
}

const DynamicFeed = dynamic(() => import('../components/Feed'), {
  loading: () => <p>Loading...</p>
});

const DynamicRenderItemAdvanced = dynamic(
  () => import('../components/RenderItemAdvanced'),
  {
    loading: () => <p>Loading...</p>
  }
);

// import RenderItemAdvanced from '../components/RenderItemAdvanced';
// import RenderSkeleton from 'components/RenderSkeleton';
const DynamicRenderSkeleton = dynamic(
  () => import('../components/RenderSkeleton'),
  {
    loading: () => <p>Loading...</p>
  }
);
import { useSupabaseClient } from '@supabase/auth-helpers-react';
import interiorOptions from 'utils/data/interiorOptions';
import colors from 'utils/data/color';
// import PricingModal from '../components/Modal';
const DynamicPricingModal = dynamic(() => import('../components/Modal'), {
  loading: () => <p>Loading...</p>
});
import HelperModal from '../components/HelperModal';
import HelperProModal from '../components/HelperProModal';
import Share from 'components/ShareButton';
import FeedbackButton from 'components/FeedbackButton';
import ProjectModal from '../components/ProjectModal';
import SaveStyleModal from '../components/SaveStyleModal';
// import PricingMini from '../components/PricingMini';
const DynamicPricingMini = dynamic(() => import('../components/PricingMini'), {
  loading: () => <p>Loading...</p>
});
// import InputPanel from '../components/InputPanel';
const DynamicInputPanel = dynamic(() => import('../components/InputPanel'), {
  loading: () => <p>Loading...</p>
});

import { trackEvent } from '../utils/analytics';
import {
  PlusIcon,
  FolderPlusIcon,
  ArrowUpRightIcon
} from '@heroicons/react/20/solid';

import { useImage } from '../contexts/ImageContext';
// import MainAppHeader from '../components/MainAppHeader';
const DynamicMainAppHeader = dynamic(
  () => import('../components/MainAppHeader'),
  {
    loading: () => <p>Loading...</p>
  }
);
import classNames from 'classnames';

import PreparingRenders from '../components/preparingRenders';
import HomePage from '../components/HomePage';
import TutorialModal from '../components/TutorialModal';
const {
  styles,
  roomTypes,
  qualityOptions,
  redesignTypes,
  privacyTypes,
  projectOptions,
  exteriorRoomTypes,
  commercialRoomTypes,
  areaOptions,
  brushTypes
} = interiorOptions;

import tutorialData from 'utils/data/tutorials';

const tutorials = tutorialData.tutorials;

export default function Example() {
  const [communityStyles, setCommunityStyles] = useState([]);

  // sorted randomly
  const [availableTutorials, setAvailableTutorials] = useState(tutorials);

  const [myStyles, setMyStyles] = useState([]);

  const { t } = useTranslation('common');
  const [selectedRoom, setSelectedRoom] = useState(roomTypes[0]);
  const [selectedStyle, setSelectedStyle] = useState(styles[0]);
  const [selectedColor, setColor] = useState(colors[0]);
  const [availableStyles, setAvailableStyles] = useState(styles);
  const [selectedRedesign, setSelectedRedesign] = useState(redesignTypes[0]);
  const [brushType, setBrushType] = useState(brushTypes[0]);
  const [selectedQuality, setSelectedQuality] = useState(qualityOptions[0]);
  const [selectedPrivacy, setSelectedPrivacy] = useState(privacyTypes[0]);
  const [selectedProject, setSelectedProject] = useState(projectOptions[0]);
  const [selectedArea, setSelectedArea] = useState({ mask_prompt: '' });
  const [availableProjects, setAvailableProjects] = useState(projectOptions);
  const [signInMessage, setSignInMessage] = useState(null);

  const [currentRender, setCurrentRender] = useState(null);

  const [currentRenders, setCurrentRenders] = useState([]);

  const [uploading, setUploadingState] = useState('blank');
  const [emailUploading, setEmailUploadingState] = useState(false);

  const [firstSignup, setSignup] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openHelper, setOpenHelper] = useState(false);
  const [openProHelper, setOpenProHelper] = useState(false);
  const [setPrivacyTrue, updateSetPrivacyTrue] = useState(false);

  const [hasMore, setHasMore] = useState(true);

  const supabaseClient = useSupabaseClient();

  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(2);

  // start and end for public feed

  // random number for public feed between 0 and 100

  const [startPublic, setStartPublic] = useState(0);
  const [endPublic, setEndPublic] = useState(2);
  const [hasMorePublic, setHasMorePublic] = useState(true);

  const [editMode, setEditMode] = useState(null);
  const [editableRender, setEditableRender] = useState(null);

  const handleEditMode = (renderId) => {
    setEditableRender(renderId);
  };

  const { imageUrl, file, setFile, setImageUrl } = useImage();

  const [publicFeed, setPublicFeed] = useState([]);
  const [privateFeed, setPrivateFeed] = useState([]);
  const [completedJobs, setCompletedJobs] = useState(0);

  const canvasRef = useRef(0);
  const { user, userDetails, subscription } = useUser();

  const [feedStyle, setFeedStyle] = useState('public');
  const [feedListLoading, setFeedListLoading] = useState('false');

  const [hasMoreRenders, setHasMoreRenders] = useState(true);
  const [hasRenderMessage, setHasRenderMessage] = useState('');

  const [previousFile, setPreviousFile] = useState(null);
  const [previousUrl, setPreviousUrl] = useState('');

  const [activeProjectFilter, setActiveProjectFilter] = useState(null);

  const [aspectRatioCode, setAspectRatioCode] = useState(null);

  const [editingMode, setEditingMode] = useState('basic');

  const [environment, setEnvironment] = useState({ display_name: 'Interior' });
  const [roomOptions, setRoomOptions] = useState(roomTypes);

  const [quickFile, setQuickFile] = useState([null]);
  const [quickImageUrl, setQuickImageUrl] = useState([null]);
  const [tutorialOpen, setTutorialOpen] = useState(false);
  const [activeTutorial, setActiveTutorial] = useState(tutorials[0]);

  useEffect(() => {
    if (environment.display_name == 'Interior') {
      setRoomOptions(roomTypes);
      setSelectedRoom(roomTypes[0]);
    } else if (environment.display_name == 'Commercial') {
      setRoomOptions(commercialRoomTypes);
      setSelectedRoom(commercialRoomTypes[0]);
    } else {
      setRoomOptions(exteriorRoomTypes);
      setSelectedRoom(exteriorRoomTypes[0]);
    }
  }, [environment]);

  const setActiveInput = (imageUrl, aspect_ratio, hijackRender = []) => {
    trackEvent('remix', {
      event_category: 'engagement',
      event_label: 'remix'
    });
    // mock file object
    const file = {
      name: 'mock',
      type: 'image/jpeg',
      size: 1000,
      lastModified: 1000,
      lastModifiedDate: new Date(),
      webkitRelativePath: '',
      path: imageUrl
    };

    setFile(file);
    setImageUrl(imageUrl);
    setPreviousFile(file);
    setPreviousUrl(imageUrl);
    setAspectRatioCode(aspect_ratio);
    // scroll to top
    if (uploading === 'completed' && hasMoreRenders) {
      setUploadingState('blank');
    }

    // setEditingMode('advanced');
    setCurrentRenders(hijackRender);
    if (hijackRender.length == 0) {
      setEditingMode('basic');
    }

    window.scrollTo(0, 0);
  };

  const hijackAdvancedEditMode = (hijackRender) => {
    hijackRender.input_source_url = hijackRender.output_url;
    setCurrentRenders([hijackRender]);
    setEditMode('advanced');
    setEditingMode('advanced');
    setActiveInput(hijackRender.output_url, hijackRender.aspect_ratio, [
      hijackRender
    ]);
    setUploadingState('completed');
  };

  const submitEmailForSignIn = async (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    // Email validation using regular expression
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailRegex.test(email)) {
      setSignInMessage('Invalid email address. Please enter a valid email.');
      return; // Stop the function if the email is not valid
    }

    setEmailUploadingState(true);

    // get timestamp for in hours:minuts:seconds

    const timestamp = new Date().getTime();

    const readable_ts = new Date(timestamp).toLocaleString();

    const { data, error } = await supabaseClient.auth.signInWithOtp({
      email: email,
      options: {
        emailRedirectTo: 'https://sofabrain.com/',

        data: { timestamp: readable_ts }
      }
    });

    if (error) {
      setEmailUploadingState(false);
      setSignInMessage(error.message);
      throw error;
    } else {
      setEmailUploadingState(false);
      setSignInMessage('Confirm your email to begin using SofaBrain');
      trackEvent('email_sign_in', {
        event_category: 'conversion',
        event_label: 'email_submission'
      });
    }
  };

  const getFeedList = async () => {
    try {
      const { data, error } = await supabaseClient
        .from('random_feed')
        .select(
          'id,created_at,input_source_url,output_url,room_type,style,completed_at,original_style,original_room_type,aspect_ratio'
        )
        .range(startPublic, endPublic);

      if (error) {
        // Log the error for debugging
        console.error('Error fetching feed data:', error.message);

        // Assume no additional items are available if an error occurs
        setHasMorePublic(false);
        return publicFeed; // Return the current state of publicFeed
      }

      if (data.length === 0) {
        setHasMorePublic(false);
        return publicFeed; // Return the current state of publicFeed if no new data is fetched
      }

      let newGrid = [...publicFeed, ...data];

      setPublicFeed(newGrid);
      setStartPublic(startPublic + 5 + 1);
      setEndPublic(endPublic + 5 + 1);

      return newGrid;
    } catch (error) {
      // Log the error
      console.error('Error in getFeedList function:', error);

      // Set hasMorePublic to false and return the current state of publicFeed
      setHasMorePublic(false);
      return publicFeed;
    }
  };

  const getMorePublicFeed = async () => {
    getFeedList();
    trackEvent('more_public_feed', {
      event_category: 'engagement',
      event_label: 'more_public_feed'
    });
  };

  const getMorePrivateFeed = async (project = null, override_start = null) => {
    const page_start = override_start ? 0 : start;
    const page_end = override_start ? 7 : end;

    let active_project = project ? project : activeProjectFilter;

    if (active_project == 'all') {
      active_project = null;
    }

    try {
      const { data, error } = await getData(
        `/api/jobs?start=${page_start}&end=${page_end}&project=${active_project}&filter=${
          active_project != null
        }`
      );
      if (error) throw Error(error.message);

      if (privateFeed.length > 0) {
        if (data.length === 0) setHasMore(false);

        if (data.length > 0) {
          if (page_start === 0) {
            var newGrid = [...data];
          } else {
            var newGrid = [...privateFeed, ...data];
          }

          // remove items with the same id
          newGrid = newGrid.filter(
            (thing, index, self) =>
              index === self.findIndex((t) => t.id === thing.id)
          );

          setPrivateFeed(newGrid);
          setStart(newGrid.length);
          setEnd(newGrid.length + 7);
          setHasMore(true);
        }
      } else {
        setPrivateFeed(data);
        setHasMore(true);
        setStart(data.length);
        setEnd(data.length + 7);
      }
    } catch (error) {
      if (error) return console.log(error.message);
    }
  };

  const checkForPrivateStyles = async (user) => {
    const user_id = user.id;

    const user_custom_styles = {
      'f7e94a5c-9faa-48c4-8ad8-96228d5': [
        {
          display_name: 'Coleurs',
          name: 'coloeurs',
          description: 'custom style'
        }
      ]
    };

    if (user_custom_styles[user_id]) {
      const nextOptions = [...user_custom_styles[user_id], ...availableStyles];

      setAvailableStyles(nextOptions);
    }
  };

  useEffect(() => {
    setFeedListLoading(false);
    if (user) {
      getMorePrivateFeed();
      checkForPrivateStyles(user);
    }
  }, [user]);

  useEffect(() => {
    if (userDetails) {
      setCompletedJobs(userDetails.completed_jobs);
      checkHasMoreRenders();
    }
    if (subscription) {
      updateSetPrivacyTrue(true);
    }
  }, [userDetails, subscription]);

  useEffect(() => {
    if (publicFeed.length == 0) {
      setFeedListLoading(true);
      getMorePublicFeed();
      setFeedListLoading(false);
    }
  }, []);

  const setActiveFilter = (filterOption) => {
    if (filterOption.id == 'all_projects') {
      setActiveProjectFilter(null);
      setStart(0);
      setEnd(7);
      setPrivateFeed([]);
      getMorePrivateFeed('all', true);
    } else {
      setActiveProjectFilter(filterOption.display_name);
      setStart(0);
      setEnd(7);
      setPrivateFeed([]);
      getMorePrivateFeed(filterOption.display_name, true);
    }
  };

  useEffect(() => {
    async function getProjects() {
      if (subscription) {
        try {
          const { data, error } = await getData(`/api/project`);

          if (error) throw Error(error.message);

          if (data.length > 0) {
            // map data so name and display name are equal to project_name

            data.map((item) => {
              item.name = item.id || item.project_name;
              item.display_name = item.project_name;
            });

            const newProjectOptions = [projectOptions[1], ...data];

            setAvailableProjects(newProjectOptions);
          }
        } catch (error) {
          if (error) throw Error(error.message);
        }
      }
    }

    async function getMyStyles() {
      try {
        if (user) {
          const { data, error } = await getData(`/api/community`);

          if (error) throw Error(error.message);

          if (data.length > 0) {
            // map data so name and display name are equal to project_name

            var newMyStyles = [...myStyles, ...data];

            newMyStyles = newMyStyles.filter(
              (thing, index, self) =>
                index === self.findIndex((t) => t.id === thing.id)
            );

            setMyStyles(newMyStyles);
          }
        }
      } catch (error) {
        if (error) throw Error(error.message);
      }
    }

    async function getCommunityStyles() {
      try {
        if (user) {
          const { data, error } = await getData(`/api/community?type=public`);

          if (error) throw Error(error.message);

          if (data.length > 0) {
            // map data so name and display name are equal to project_name

            var newCommunity = [...communityStyles, ...data];

            // filter repeated items

            newCommunity = newCommunity.filter(
              (thing, index, self) =>
                index === self.findIndex((t) => t.id === thing.id)
            );

            setCommunityStyles(newCommunity);
          }
        }
      } catch (error) {
        if (error) throw Error(error.message);
      }
    }

    getProjects();
    getMyStyles();
    getCommunityStyles();
  }, [user]);

  const setFeedType = (type) => {
    setFeedStyle(type);
  };

  const compressImage = (canvas, quality = 0.8) => {
    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          resolve(blob);
        },
        'image/webp',
        quality
      );
    });
  };

  const resizeImage = async (img, height, width, name, type) => {
    // Set New Image Dimensions (must keep aspect ratio and lowest value must be 512px)

    // determine orientation

    const adjuster = 1.0;

    const supported_configs_low = {
      square: { width: adjuster * 512, height: adjuster * 512 },
      phone: { width: adjuster * 512, height: 912 * adjuster },
      landscape_small: { width: 768 * adjuster, height: adjuster * 512 },
      landscape: { width: 912 * adjuster, height: adjuster * 512 },
      tall_square: { width: 576 * adjuster, height: 768 * adjuster }
    };

    const supported_configs_high = {
      square: { width: 768, height: 768 },
      phone: { width: 640, height: 1136 },
      landscape: { width: 1136, height: 640 },
      landscape_small: { width: 1075, height: 717 },
      tall_square: { width: 664, height: 880 }
    };

    const aspectRatio = height / width;

    const aspectToConfig = {
      square: 1,
      phone: 1.77,
      landscape: 0.56,
      landscape_small: 512 / 768,
      tall_square: 1.33
    };

    // loop through supported configs and find the closest match

    try {
      let closestMatch = null;

      for (const [key, value] of Object.entries(aspectToConfig)) {
        if (closestMatch == null) {
          closestMatch = key;
        } else {
          if (
            Math.abs(aspectRatio - value) <
            Math.abs(aspectRatio - aspectToConfig[closestMatch])
          ) {
            closestMatch = key;
          }
        }
      }

      setAspectRatioCode(closestMatch);

      const lever = 'low';

      const active_config =
        lever == 'low' ? supported_configs_low : supported_configs_high;

      var newWidth = active_config[closestMatch].width;
      var newHeight = active_config[closestMatch].height;
    } catch (error) {
      console.log('error', error);
      // throw Error(error.message);

      const target_size = 640;

      var newWidth =
        width > height
          ? Math.floor((target_size * width) / height)
          : target_size;
      var newHeight =
        height > width
          ? Math.floor((target_size * height) / width)
          : target_size;

      console.log('using previous config image');
    }

    // Resize Image
    const canvas = document.createElement('canvas');
    canvas.width = newWidth;
    canvas.height = newHeight;

    canvas.current = canvasRef;

    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0, newWidth, newHeight);

    // Set Preview url
    const compressedBlob = await compressImage(canvas);

    // Set File to resized image

    setFile(new File([compressedBlob], name, { type: 'image/webp' }));
    setImageUrl(canvas.toDataURL('image/webp'));

    // Set Image Dimensions
  };

  const checkHasMoreRenders = () => {
    // return the max value of either completed jobs or userDetails.completed_jobs
    const localCompletedJobs = Math.max(
      completedJobs,
      userDetails.completed_jobs
    );
    if (subscription) {
      setHasMoreRenders(true);
      return;
    } else {
      // if (
      //   localCompletedJobs === 5 ||
      //   localCompletedJobs === 10 ||
      //   localCompletedJobs === 15 ||
      //   localCompletedJobs === 20 ||
      //   localCompletedJobs === 25 ||
      //   localCompletedJobs === 30 ||
      //   localCompletedJobs > 30
      // ) {
      //   setHasRenderMessage(String(localCompletedJobs));
      //   if (localCompletedJobs > 30) {
      //     setHasRenderMessage('30');
      //   }
      // }
      if (
        // localCompletedJobs < userDetails?.render_limit ||
        localCompletedJobs < 4
      ) {
        setHasMoreRenders(true);
        return;
      }
    }

    setHasMoreRenders(false);
  };

  const refreshFeed = () => {
    setStart(0);
    setEnd(7);
    setPrivateFeed([]);
    getMorePrivateFeed('all', true);
    setFeedType('private');
  };

  const addToUploadedRenders = (render) => {
    setCurrentRenders([]);
    setSelectedQuality(qualityOptions[0]);
    setSelectedPrivacy(privacyTypes[0]);

    // const filteredRenders = privateFeed.filter((r) => r.id !== render.id);
    // setPrivateFeed([render, ...filteredRenders]);
    refreshFeed();
  };

  const showCurrentRenderSingle = (
    render,
    shouldAddToPrivate = false,
    shouldSetEditable = false
  ) => {
    setCurrentRender(render);

    if (shouldAddToPrivate) {
      const filteredRenders = privateFeed.filter((r) => r.id !== render.id);

      setPrivateFeed([render, ...filteredRenders]);
      setFeedType('private');
    }

    if (shouldSetEditable) {
      handleEditMode(render.id);
    }

    const nextJobs = completedJobs + 1;

    setCompletedJobs(nextJobs);

    userDetails.completed_jobs = completedJobs + 1;
  };

  const showCurrentRender = (
    renderArr,
    shouldAddToPrivate = false,
    shouldSetEditable = false,
    tripHasMore = false
  ) => {
    if (tripHasMore) {
      setHasMoreRenders(false);
      return;
    }

    setCurrentRenders([...currentRenders, ...renderArr]);
    if (shouldAddToPrivate) {
      // const filteredRenders = privateFeed.filter((r) => r.id !== render.id);
      const filteredRenders = [...privateFeed];

      setPrivateFeed([...currentRenders, ...filteredRenders]);
      setFeedType('private');
    }

    if (shouldSetEditable) {
      handleEditMode(renderArr[0].id);
    }

    const nextJobs = completedJobs + 1;

    setCompletedJobs(nextJobs);

    userDetails.completed_jobs = completedJobs + 1;

    trackEvent('render_completed', {
      event_category: 'engagement',
      event_label: 'render_complete'
    });
  };

  const checkIfLoggedIn = (e) => {
    if (!user) setSignup(true);
  };

  const clickHomeButton = (editingMode) => {
    setCurrentRenders([]);

    setEditingMode(editingMode === 'quickstyles' ? 'quickstyles' : 'basic');

    setUploadingState('blank');
    if (currentRender) {
      addToUploadedRenders(currentRender);
    }
    refreshFeed();
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 200);
  };

  const selectFile = (e) => {
    trackEvent('select_file', {
      category: 'engagement',
      label: 'select_file'
    });

    try {
      if (!e.target.files[0]) return;

      evaluateFileType(e.target.files[0]);

      setFile(e.target.files[0]);

      const img = new Image();
      img.src = window.URL.createObjectURL(e.target.files[0]);

      img.onload = function () {
        const name = e.target.files[0].name;
        const type = e.target.files[0].type;

        const width = img.naturalWidth;
        const height = img.naturalHeight;

        resizeImage(img, height, width, name, type);
      };
    } catch (error) {
      console.log(error);
    }
  };

  // get current url from router
  const { asPath } = useRouter();

  const [openProjectModal, setOpenProjectModal] = useState(false);
  const [openSaveStyleModal, setSaveStyleModal] = useState(false);

  const [styleType, setStyleType] = useState('classical');

  const [selectedCustomStyle, setSelectedCustomStyle] = useState(
    communityStyles[0]
  );

  const [selectedMyStyle, setSelectedMyStyle] = useState(myStyles[0]);

  return (
    <div>
      {!user && (
        <HomePage
          firstSignup={firstSignup}
          signInMessage={signInMessage}
          emailUploading={emailUploading}
          submitEmailForSignIn={submitEmailForSignIn}
          selectFile={selectFile}
          imageUrl={imageUrl}
          checkIfLoggedIn={checkIfLoggedIn}
          data={publicFeed}
          translator={t}
        ></HomePage>
      )}

      <div className=" md:grid md:grid-cols-2 md:justify-center">
        <TutorialModal
          passOpenModal={setTutorialOpen}
          openModal={tutorialOpen}
          tutorial={activeTutorial}
        />
        <DynamicPricingModal
          passOpenModal={setOpenModal}
          openModal={openModal}
          translator={t}
        ></DynamicPricingModal>
        <HelperModal
          passOpenModal={setOpenHelper}
          openModal={openHelper}
        ></HelperModal>
        <HelperProModal
          passOpenModal={setOpenProHelper}
          openModal={openProHelper}
        ></HelperProModal>
        <ProjectModal
          passOpenModal={setOpenProjectModal}
          openModal={openProjectModal}
          userId={user?.id}
          availableProjects={availableProjects}
          setAvailableProjects={setAvailableProjects}
          setSelectedProject={setSelectedProject}
        />
        <SaveStyleModal
          passOpenModal={setSaveStyleModal}
          openModal={openSaveStyleModal}
          userId={user?.id}
          inspirationImages={quickImageUrl}
          setMyStyles={setMyStyles}
          myStyles={myStyles}
        />

        <div className="md:flex md:flex-col">
          {user ? <FeedbackButton userId={user?.id} url={asPath} /> : null}

          {user && !subscription && !hasMoreRenders && (
            <div className="flex justify-center mb-6">
              <DynamicPricingMini
                message={5}
                translator={t}
                // closeMessage={setHasRenderMessage}
              ></DynamicPricingMini>
            </div>
          )}

          {user && subscription && !hasMoreRenders && (
            <div className="flex flex-col justify-center px-4 items-center text-center mt-10">
              <h1 className="text-3xl">Sorry About the Incovenience 😓</h1>
              <h2>
                We're currently experiencing high levels of demand. Please try
                again in a few minutes.
              </h2>
              <h3>
                Please Reload Window the to Continue. We're working on a fix for
                this issue.
              </h3>
              <h4>Use our support channels if you need urgent assistance.</h4>
            </div>
          )}

          {hasMoreRenders && (
            <div className="p-3 pt-0">
              <DynamicMainAppHeader
                setOpenHelper={setOpenHelper}
                setOpenProHelper={setOpenProHelper}
                firstSignup={firstSignup}
                signInMessage={signInMessage}
                emailUploading={emailUploading}
                submitEmailForSignIn={submitEmailForSignIn}
                selectFile={selectFile}
                checkIfLoggedIn={checkIfLoggedIn}
                uploading={uploading}
                currentRenders={currentRenders}
              />
              <Transition
                show={uploading !== 'completed'}
                enter="duration-200 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                className={'w-full'}
              >
                {/* {uploading !== 'completed' && ( */}
                <div className="">
                  {/* User is logged in */}

                  <DynamicInputPanel
                    uploading={uploading}
                    hasMoreRenders={hasMoreRenders}
                    selectFile={selectFile}
                    checkIfLoggedIn={checkIfLoggedIn}
                    selectedRoom={selectedRoom}
                    setSelectedRoom={setSelectedRoom}
                    roomTypes={roomOptions}
                    environment={environment}
                    setEnvironment={setEnvironment}
                    colors={colors}
                    setOpenModal={setOpenModal}
                    selectedStyle={selectedStyle}
                    setSelectedStyle={setSelectedStyle}
                    setColor={setColor}
                    selectedColor={selectedColor}
                    availableStyles={availableStyles}
                    selectedRedesign={selectedRedesign}
                    setSelectedRedesign={setSelectedRedesign}
                    redesignTypes={redesignTypes}
                    selectedProject={selectedProject}
                    setSelectedProject={setSelectedProject}
                    availableProjects={availableProjects}
                    setOpenProjectModal={setOpenProjectModal}
                    selectedQuality={selectedQuality}
                    setSelectedQuality={setSelectedQuality}
                    qualityOptions={qualityOptions}
                    selectedPrivacy={selectedPrivacy}
                    setSelectedPrivacy={setSelectedPrivacy}
                    privacyTypes={privacyTypes}
                    aspectRatioCode={aspectRatioCode}
                    showCurrentRender={showCurrentRender}
                    setUploadingState={setUploadingState}
                    setSignup={setSignup}
                    setPreviousFile={setPreviousFile}
                    setPreviousUrl={setPreviousUrl}
                    previousFile={previousFile}
                    previousUrl={previousUrl}
                    checkHasMoreRenders={checkHasMoreRenders}
                    editingMode={editingMode}
                    setEditingMode={setEditingMode}
                    setPrivacyTrue={setPrivacyTrue}
                    setActiveInput={setActiveInput}
                    quickFile={quickFile}
                    setQuickFile={setQuickFile}
                    quickImageUrl={quickImageUrl}
                    setQuickImageUrl={setQuickImageUrl}
                    selectedArea={selectedArea}
                    setSelectedArea={setSelectedArea}
                    areaOptions={areaOptions}
                    myStyles={myStyles}
                    communityStyles={communityStyles}
                    setMyStyles={setMyStyles}
                    setCommunityStyles={setCommunityStyles}
                    styleType={styleType}
                    setStyleType={setStyleType}
                    selectedCustomStyle={selectedCustomStyle}
                    setSelectedCustomStyle={setSelectedCustomStyle}
                    selectedMyStyle={selectedMyStyle}
                    setSelectedMyStyle={setSelectedMyStyle}
                    selectedBrushType={brushType}
                  />
                </div>
                {/* )} */}
              </Transition>
            </div>
          )}

          {/* Show users Renders */}
          {/* <Transition
        show={uploading === 'waiting' && !currentRender}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-0 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
        className={'w-full'}
      >
        <div className="mx-auto max-w-2xl pb-6 sm:pb-10 px-4 sm:px-6 lg:max-w-6xl lg:px-8">
          <div>
            <Queue passOpenModal={setOpenModal}></Queue>
          </div>
        </div>
      </Transition> */}

          <Transition
            show={uploading === 'uploading'}
            enter="duration-500 ease-out"
            enterFrom="opacity-100 -mt-5"
            enterTo="opacity-100"
            className={'w-full'}
          >
            <div className="mx-auto max-w-4xl pb-6 sm:pb-10 px-4 sm:px-6 lg:px-8">
              <div>
                <DynamicRenderSkeleton
                  selectedRoom={selectedRoom}
                  selectedStyle={selectedStyle}
                  currentRenders={currentRenders}
                />
              </div>
            </div>
          </Transition>

          <div className={'w-full'}>
            {currentRenders.length > 0 &&
              uploading === 'completed' &&
              (editingMode === 'basic' || editingMode === 'quickstyles') && (
                <div className="mx-auto pb-6 sm:pb-10 px-4 sm:px-6 lg:px-8 max-w-[600px]">
                  <PreparingRenders
                    setOpenModal={setOpenModal}
                    subscription={subscription}
                    currentRenders={currentRenders}
                    handleEditMode={handleEditMode}
                    editableRender={editableRender}
                    hijackAdvancedEditMode={hijackAdvancedEditMode}
                    setActiveInput={setActiveInput}
                  ></PreparingRenders>
                </div>
              )}
          </div>

          <Transition
            show={currentRenders.length > 0 && editingMode === 'advanced'}
            // show={true}
            enter="duration-200 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-0 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
            className={'w-full z-10 relative mb-5'}
          >
            <div
              // className="grid grid-cols-1 md:grid-cols-2 gap-x-2 gap-2 content-around"
              className={classNames(
                'grid grid-cols-1 gap-x-2 gap-2 max-w-2xl mx-auto'
              )}
            >
              <DynamicRenderItemAdvanced
                passOpenModal={setOpenModal}
                subscription={subscription}
                render={currentRenders[0]}
                key={currentRenders[0]?.id}
                preview={true}
                editable={true}
                HDImage={currentRenders[0]?.quality}
                handleEditMode={handleEditMode}
                editableRender={editableRender}
                selectedRoom={selectedRoom}
                selectedStyle={selectedStyle}
                setSelectedRoom={setSelectedRoom}
                roomTypes={roomOptions}
                environment={environment}
                setEnvironment={setEnvironment}
                setOpenModal={setOpenModal}
                setSelectedStyle={setSelectedStyle}
                availableStyles={availableStyles}
                setEditingMode={setEditingMode}
                clickHomeButton={clickHomeButton}
                updatePrivateFeed={refreshFeed}
                setActiveInput={setActiveInput}
                hijackAdvancedEditMode={hijackAdvancedEditMode}
                selectedRedesign={brushType}
                setSelectedRedesign={setBrushType}
                redesignTypes={brushTypes}
                translator={t}
                selectedBrushType={brushType}
                communityStyles={communityStyles}
                myStyles={myStyles}
                setSelectedMyStyle={setSelectedMyStyle}
                styleType={styleType}
                setSelectedCustomStyle={setSelectedCustomStyle}
                setStyleType={setStyleType}
                selectedMyStyle={selectedMyStyle}
                selectedCustomStyle={selectedCustomStyle}
                quickFile={quickFile}
                setQuickFile={setQuickFile}
                quickImageUrl={quickImageUrl}
                setQuickImageUrl={setQuickImageUrl}
                checkIfLoggedIn={checkIfLoggedIn}
              />
            </div>
          </Transition>

          {/* After render flow */}

          {uploading === 'completed' &&
            hasMoreRenders &&
            editingMode !== 'advanced' && (
              <div className="flex flex-col items-center py-2">
                <div className="flex flex-row justify-center items-center px-5 gap-2 mb-4">
                  <button
                    onClick={() => {
                      clickHomeButton(editingMode);
                      // checkHasMoreRenders();
                    }}
                    className="py-3 px-5 border border-transparent text-sm md:text-base font-medium rounded-lg shadow-sm text-white bg-black hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 flex items-center justify-center"
                  >
                    <PlusIcon className="w-6"></PlusIcon>
                    <span>Try a new Style or Photo</span>
                  </button>

                  {editingMode === 'quickstyles' && (
                    <button
                      onClick={() => setSaveStyleModal(true)}
                      className="py-3 px-5 border border-transparent text-sm md:text-base font-medium rounded-lg shadow-sm text-white bg-pink-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 flex items-center justify-center"
                    >
                      <FolderPlusIcon className="w-6"></FolderPlusIcon>
                      <span>Save Style</span>
                    </button>
                  )}

                  <Share
                    label="share"
                    url={`${getURL()}share/${currentRenders[0]?.id}`}
                    text={`${getURL()}share/${currentRenders[0]?.id}`}
                    title={`${getURL()}share/${currentRenders[0]?.id}`}
                  />
                </div>
                <div className="text-xs px-6 text-center">
                  <span className="font-extrabold">Tip:</span> Click on the top
                  right of your favorite image to upscale it and get the best
                  results
                </div>
              </div>
            )}
        </div>

        {/* No Renders Left */}
        {user && (
          <div className="bg-neutral-100 ">
            {/* Public feed */}
            <h2 className="font-bold text-lg pt-8 mb-0 sm:text-xl sm:pb-0 text-center text-slate-900">
              Explore recent designs by{' '}
              <span className="hanno-font gradient-text">sofabrain</span>
            </h2>

            <div className="py-5">
              <div className="mb-0 sm:flex sm:flex-col sm:align-center">
                <div className="relative self-center ml-5 mr-5 mt-4 bg-neutral-300 rounded-3xl flex sm:mt-4">
                  <button
                    onClick={() => setFeedType('public')}
                    type="button"
                    className={`${
                      feedStyle === 'public'
                        ? 'relative w-1/2 bg-white shadow-lg text-black'
                        : 'ml-0.5 relative w-1/2 border border-transparent text-slate-900'
                    } rounded-2xl m-1 py-2 text-sm font-bold whitespace-nowrap focus:outline-none focus:ring-2  focus:ring-opacity-50 focus:z-10 sm:w-auto sm:px-8 focus:ring-purple-500`}
                  >
                    Public Feed
                  </button>
                  <button
                    onClick={() => setFeedType('private')}
                    type="button"
                    className={`${
                      feedStyle === 'private'
                        ? 'relative w-1/2 bg-white shadow-sm text-black focus:ring-purple-500'
                        : 'ml-0.5 relative w-1/2 border border-transparent text-slate-900'
                    } rounded-2xl m-1 py-2 text-sm font-bold whitespace-nowrap focus:outline-none focus:ring-2  focus:ring-opacity-50 focus:z-10 sm:w-auto sm:px-8`}
                  >
                    {!subscription ? 'My Renders' : 'My Pro Renders'}
                  </button>
                </div>
              </div>
            </div>
            <div
              className="mx-auto max-w-2xl py-4 px-4 sm:py-10 sm:px-6 lg:px-8 max-h-[80vh] overflow-auto snap-"
              id="scrollableDiv"
            >
              {feedListLoading ? (
                <div className="flex justify-center">
                  <svg
                    aria-hidden="true"
                    className="w-12 h-12 mr-2 text-gray-200 animate-spin dark:text-gray-300 fill-indigo-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="pt-3 animate-pulse text-xl">Loading...</span>
                </div>
              ) : feedStyle == 'public' ? (
                <DynamicFeed
                  passOpenModal={setOpenModal}
                  subscription={subscription}
                  data={publicFeed}
                  next={getMorePublicFeed}
                  hasMore={hasMorePublic}
                  setActiveInput={setActiveInput}
                  editable={false}
                  loader={
                    <h3 className="text-center">
                      {' '}
                      {/* Your renders will appear here... */}
                    </h3>
                  }
                  endMessage={<h4></h4>}
                />
              ) : (
                <DynamicFeed
                  passOpenModal={setOpenModal}
                  subscription={subscription}
                  data={privateFeed}
                  next={getMorePrivateFeed}
                  setActiveInput={setActiveInput}
                  showFeedFilter={subscription && availableProjects.length > 1}
                  feedFilterOptions={availableProjects}
                  activeFilter={activeProjectFilter}
                  setActiveFilter={setActiveFilter}
                  editable={true}
                  hasMore={hasMore}
                  editMode={editMode}
                  editableRender={editableRender}
                  handleEditMode={handleEditMode}
                  hijackAdvancedEditMode={hijackAdvancedEditMode}
                  loader={
                    <h3 className="text-center">
                      {' '}
                      {/* Your renders will appear here... */}
                    </h3>
                  }
                  endMessage={<h4></h4>}
                />
              )}
            </div>
          </div>
        )}
      </div>

      <div className="max-w-7xl items-center flex flex-col justify-center justify-items-center mx-auto mt-10">
        <div className="hanno-font text-3xl gradient-text">Tutorials</div>
        <div className="flex overflow-auto my-10  gap-2" id="scrollableDiv">
          {availableTutorials.map((tutorial, index) => (
            <div
              className="relative flex-none"
              key={`${tutorial.title}-${index}`}
            >
              <NextImage
                className="object-cover h-[200px] w-[250px]"
                src={tutorial.thumb}
                height={200}
                width={250} // Changed from 200 to 250 to match className width
                style={{ objectFit: 'cover' }}
                onClick={() => {
                  setActiveTutorial(tutorial);
                  setTutorialOpen(true);
                }}
              />
              <div className="bottom-0 w-full py-2 absolute bg-zinc-800 to-transparent flex justify-center items-center gap-2">
                <div className="text-center text-white text-xs font-bold uppercase">
                  {tutorial.title}{' '}
                  {/* Changed from tutorial.name to tutorial.title */}
                </div>
                <ArrowUpRightIcon className="h-6 w-6 text-white fill-white" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
