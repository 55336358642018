import React, { useState, useEffect } from 'react';

const TypingText = ({
  words = ['interiors', 'gardens', 'kitchens', 'bathrooms']
}) => {
  const [state, setState] = useState({
    wordIndex: 0,
    text: '',
    isDeleting: false,
    loopNum: 0,
    typingSpeed: 0
  });

  const handleType = () => {
    const { isDeleting, loopNum, text, typingSpeed } = state;
    const i = loopNum % words.length;
    const fullText = words[i];

    setState((prevState) => ({
      ...prevState,
      text: isDeleting
        ? fullText.substring(0, text.length - 1)
        : fullText.substring(0, text.length + 1),
      typingSpeed: isDeleting ? 30 : 200
    }));

    if (!isDeleting && text === fullText) {
      setTimeout(
        () => setState((prevState) => ({ ...prevState, isDeleting: true })),
        500
      );
    } else if (isDeleting && text === '') {
      setState((prevState) => ({
        ...prevState,
        isDeleting: false,
        loopNum: loopNum + 1,
        wordIndex: (prevState.wordIndex + 1) % words.length
      }));
    }
  };

  useEffect(() => {
    const typingTimeout = setTimeout(handleType, state.typingSpeed);
    return () => clearTimeout(typingTimeout);
  }, [state.text, state.isDeleting, state.typingSpeed]);

  return (
    <span>
      <span className="font-extrabold text-transparent text-[50px] md:text-7xl bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600 ">
        {`${state.text}`}
        {state.text === '' ? ' ' : null}
        <span id="cursor">|</span>
      </span>
    </span>
  );
};

export default TypingText;
